function isDate(obj) {
	if (obj && typeof obj == "object") {
		return !!(obj.getFullYear && obj.getMonth && obj.getDate);
	} else {
		return false;
	}
}

module.exports = {
	isDate: isDate
};