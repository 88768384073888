module.exports = function(gantt){

	function deprecated(badCode, goodCode) {

		var formatting = gantt.env.isIE ? "" : "%c";


		var message = [
			formatting, "\"", badCode, "\"",  formatting,
			" has been deprecated in dhtmlxGantt v4.0 and will stop working in v6.0. Use ",
			formatting, "\"", goodCode, "\"",  formatting,
			" instead. \nSee more details at http://docs.dhtmlx.com/gantt/migrating.html "
		].join("");

		var log = window.console.warn || window.console.log;

		var args = [message];
		if(!gantt.env.isIE){
			args = args.concat(["font-weight:bold", "font-weight:normal", "font-weight:bold", "font-weight:normal"]);
		}

		log.apply(window.console, args);
	}

	function wrapDeprecated(method) {
		return function () {
			deprecated("dhtmlx." + method, "gantt." + method);
			return gantt[method].apply(gantt, arguments);
		};
	}

	/* dhtmlx */


	if (!window.dhtmlx)
		window.dhtmlx = {};

	var dhtmlxMethods = [
		"message",
		"alert",
		"confirm",
		"modalbox",
		"uid",
		"copy",
		"mixin",
		"defined",
		"bind",
		"assert"
	];

	var dynMethods = [];

	for(var i = 0; i < dhtmlxMethods.length; i++){
		// wrap dhtmlx methods with 'deprecated' warnings
		// do not wrap if methods are defined by dhtmlxSuite
		if(!window.dhtmlx[dhtmlxMethods[i]]){
			dynMethods.push(dhtmlxMethods[i]);
			dhtmlx[dhtmlxMethods[i]] = wrapDeprecated(dhtmlxMethods[i]);
		}
	}

	gantt.attachEvent("onDestroy", function(){
		for(var i = 0; i < dynMethods.length; i++){
			delete window.dhtmlx[dynMethods[i]];
		}
		dynMethods = null;

	});

	/* global functions */


	if (!window.dataProcessor) {
		window.dataProcessor = function (url) {
			deprecated("new dataProcessor(url)", "new gantt.dataProcessor(url)");
			return new gantt.dataProcessor(url);
		};
		gantt.attachEvent("onDestroy", function(){
			window.dataProcessor = null;
		});
	}

};