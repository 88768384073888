function createResourceMethods(gantt){

	var resourceTaskCache = {},
		resourceLoadCache = {};

	gantt.$data.tasksStore.attachEvent("onStoreUpdated", function(){
		resourceTaskCache = {};
		resourceLoadCache = {};
	});

	function getTaskBy(propertyName, propertyValue){
		if(typeof propertyName == "function"){
			return filterResourceTasks(propertyName);
		}else{
			if(propertyValue instanceof Array){
				return getResourceTasks(propertyName, propertyValue);
			}else{
				return getResourceTasks(propertyName, [propertyValue]);
			}
		}
	}

	function filterResourceTasks(filter){
		var res = [];
		gantt.eachTask(function (task) {
			if (filter(task)) {
				res.push(task);
			}
		});
		return res;
	}

	function getResourceTasks(property, resourceIds) {
		var res;

		var cacheKey = resourceIds.join("_") + "_" + property;

		var resourceHash = {};
		for(var i = 0; i < resourceIds.length; i++){
			resourceHash[resourceIds[i]] = true;
		}

		if(!resourceTaskCache[cacheKey]){
			res = resourceTaskCache[cacheKey] = [];
			gantt.eachTask(function (task) {
				if (resourceHash[task[property]] && task.type != gantt.config.types.project) {
					res.push(task);
				}
			});
		}else{
			res = resourceTaskCache[cacheKey];
		}

		return res;
	}

	function getResourceLoad(resourceId, resourceProperty, scale, timeline){
		var cacheKey = [resourceId, resourceProperty, scale.unit, scale.step].join("_");
		var res;
		if(!resourceTaskCache[cacheKey]){
			res = resourceTaskCache[cacheKey] = calculateResourceLoad(resourceId, resourceProperty, scale, timeline);

		}else{
			res = resourceTaskCache[cacheKey];
		}
		return res;
	}

	function calculateResourceLoad(resourceProperty, resourceId, scale, timeline) {

		var tasks = getTaskBy(resourceProperty, resourceId);
		var step = scale.unit;
		var timegrid = {};

		for (var i = 0; i < tasks.length; i++) {
			var task = tasks[i];

			var currDate = gantt.date[step + "_start"](new Date(task.start_date));

			while (currDate < task.end_date) {

				var date = currDate;
				currDate = gantt.date.add(currDate, 1, step);

				if (!gantt.isWorkTime({date: date, task: task, unit: step})) {
					continue;
				}

				var timestamp = date.valueOf();
				if (!timegrid[timestamp]){
					timegrid[timestamp] = [];
				}

				timegrid[timestamp].push(task);
			}
		}

		var timetable = [];
		var start, end, tasks;
		var config = timeline.$getConfig();

		for(var i = 0; i < scale.trace_x.length; i++){
			start = new Date(scale.trace_x[i]);
			end = gantt.date.add(start, 1, step);
			tasks = timegrid[start.valueOf()] || [];
			if(tasks.length || config.resource_render_empty_cells){
				timetable.push({
					start_date: start,
					end_date: end,
					tasks: tasks
				});
			}

		}

		return timetable;
	}

	function renderResourceLine(resource, timeline) {
		var config = timeline.$getConfig(),
			templates = timeline.$getTemplates();
		var timetable = getResourceLoad(config.resource_property, resource.id, timeline.getScale(), timeline);

		var cells = [];
		for (var i = 0; i < timetable.length; i++) {

			var day = timetable[i];

			var css = templates.resource_cell_class(day.start_date, day.end_date, resource, day.tasks);
			var content = templates.resource_cell_value(day.start_date, day.end_date, resource, day.tasks);

			if(css || content){
				var sizes = timeline.getItemPosition(resource, day.start_date, day.end_date);
				var el = document.createElement('div');
				el.className = ["gantt_resource_marker", css].join(" ");

				el.style.cssText = [
					'left:' + sizes.left + 'px',
					'width:' + sizes.width + 'px',
					'height:' + (config.row_height - 1) + 'px',
					'line-height:' + (config.row_height - 1) + 'px',
					'top:' + sizes.top + 'px'
				].join(";");

				if(content)
					el.innerHTML = content;

				cells.push(el);
			}

		}

		var row = null;
		if(cells.length){
			row = document.createElement("div");
			for(var i = 0; i < cells.length; i++){
				row.appendChild(cells[i]);
			}
		}

		return row;
	}
	return {
		renderLine: renderResourceLine,
		filterTasks: getTaskBy
	};
}

module.exports = function(gantt){
	var methods = createResourceMethods(gantt);

	gantt.getTaskBy = methods.filterTasks;
	gantt.$ui.layers.resourceRow = methods.renderLine;
	gantt.config.resource_property = "owner_id";
	gantt.config.resource_store = "resource";
	gantt.config.resource_render_empty_cells = false;

	gantt.templates.resource_cell_class = function(start, end, resource, tasks){
		var css = "";
		if (tasks.length <= 1) {
			css = "gantt_resource_marker_ok";
		} else {
			css = "gantt_resource_marker_overtime";
		}
		return css;
	};

	gantt.templates.resource_cell_value = function(start, end, resource, tasks){
		return tasks.length * 8;
	};
};


