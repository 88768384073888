var utils = require("../../utils/utils");
var createTasksFacade = require("./datastore_tasks"),
	createLinksFacade = require("./datastore_links"),
	DataStore = require("../datastore/datastore"),
	TreeDataStore = require("../datastore/treedatastore"),
	createDatastoreSelect = require("../datastore/select");
var datastoreRender = require("../datastore/datastore_render");

function getDatastores(){
	var storeNames = this.$services.getService("datastores");
	var res = [];
	for(var i = 0; i < storeNames.length; i++){
		res.push(this.getDatastore(storeNames[i]));
	}
	return res;
}

var createDatastoreFacade = function(){
	return {
	createDatastore: function(config){

		var $StoreType = (config.type || "").toLowerCase() == "treedatastore" ? TreeDataStore : DataStore;

		if(config){
			var self = this;
			config.openInitially = function(){ return self.config.open_tree_initially; };
		}

		var store = new $StoreType(config);
		this.mixin(store, createDatastoreSelect(store));

		if(config.name){

			this.$services.setService("datastore:" + config.name, function(){return store;});
			var storeList = this.$services.getService("datastores");
			if(!storeList){
				storeList = [];
				this.$services.setService("datastores", function(){return storeList;});
			}
			storeList.push(config.name);

			datastoreRender.bindDataStore(config.name, this);
		}

		return store;
	},
	getDatastore: function(name){
		return this.$services.getService("datastore:" + name);
	},

	refreshData: function () {
		var scrollState = this.getScrollState();
		this.callEvent("onBeforeDataRender", []);

		var stores = getDatastores.call(this);
		for(var i = 0; i < stores.length; i++){
			stores[i].refresh();
		}

		if(scrollState.x || scrollState.y){
			this.scrollTo(scrollState.x, scrollState.y);
		}
		this.callEvent("onDataRender", []);
	},

	isChildOf: function(childId, parentId){
		return this.$data.tasksStore.isChildOf(childId, parentId);
	},

	refreshTask: function (taskId, refresh_links) {
		var task = this.getTask(taskId);
		if (task && this.isTaskVisible(taskId)) {

			this.$data.tasksStore.refresh(taskId, !!this.getState().drag_id);// do quick refresh during drag and drop

			if (refresh_links !== undefined && !refresh_links)
				return;
			for (var i = 0; i < task.$source.length; i++) {
				this.refreshLink(task.$source[i]);
			}
			for (var i = 0; i < task.$target.length; i++) {
				this.refreshLink(task.$target[i]);
			}
		}else if(gantt.isTaskExists(taskId) && gantt.isTaskExists(gantt.getParent(taskId))){
			gantt.refreshTask(gantt.getParent(taskId));
		}

	},
	refreshLink: function (linkId) {
		this.$data.linksStore.refresh(linkId, !!this.getState().drag_id);// do quick refresh during drag and drop
	},

	silent: function(code){
		var gantt = this;
		gantt.$data.tasksStore.silent(function(){
			gantt.$data.linksStore.silent(function(){
				code();
			});
		});
	},

	clearAll: function () {
		var stores = getDatastores.call(this);
		for(var i = 0; i < stores.length; i++){
			stores[i].clearAll();
		}

		this._update_flags();
		this.userdata = {};
		this.callEvent("onClear", []);
		this.render();
	},
	_clear_data: function () {
		this.$data.tasksStore.clearAll();
		this.$data.linksStore.clearAll();
		this._update_flags();
		this.userdata = {};
	},

	selectTask: function(id){
		var store = this.$data.tasksStore;
		if(!this.config.select_task)
			return false;
		if (id){

			store.select(id);
		}
		return store.getSelectedId();
	},
	unselectTask: function(id){
		var store = this.$data.tasksStore;
		store.unselect(id);
	},
	getSelectedId: function() {
		return this.$data.tasksStore.getSelectedId();
	}
};
};

function createFacade(){
	var res = utils.mixin({}, createDatastoreFacade());
	utils.mixin(res, createTasksFacade());
	utils.mixin(res, createLinksFacade());
	return res;
}




module.exports = {create: createFacade};