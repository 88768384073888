var getKeyboardMapping = require("./keyboard_mappings");
var textEditorFactory = require("./editors/text"),
	numberEditorFactory = require("./editors/number"),
	selectEditorFactory = require("./editors/select"),
	dateEditorFactory = require("./editors/date"),
	predecessorEditorFactory = require("./editors/predecessor");
var utils = require("../../../../utils/utils");
var domHelpers = require("../../../../utils/dom_helpers");
var eventable = require("../../../../utils/eventable");

function initConfigs(gantt){
	gantt.config.editor_types = {
		text: new (textEditorFactory(gantt))(),
		number: new (numberEditorFactory(gantt))(),
		select: new (selectEditorFactory(gantt))(),
		date: new (dateEditorFactory(gantt))(),
		predecessor: new (predecessorEditorFactory(gantt))()
	};
}

function create(gantt){
	var keyboardMapping = getKeyboardMapping();

	var eventBus = {};
	eventable(eventBus);

	function createGridEditors(grid) {

		function _getGridCellFromNode(node){
			var row = domHelpers.locateAttribute(node, grid.$config.item_attribute);
			var cell = domHelpers.locateAttribute(node, "data-column-name");
			if(cell){
				var columnName = cell.getAttribute("data-column-name");
				var id = row.getAttribute(grid.$config.item_attribute);
				return {
					id: id,
					columnName: columnName
				};
			}
			return null;

		}

		function _getEditorPosition(itemId, columnName) {
			var top = grid.getItemTop(itemId);
			var height = grid.getItemHeight(itemId);
			var cols = grid.getGridColumns();
			var left = 0,
				width = 0;

			for (var i = 0; i < cols.length; i++) {
				if (cols[i].name == columnName) {
					width = cols[i].width;
					break;
				}
				left += cols[i].width;
			}
			return {
				top: top,
				left: left,
				height: height,
				width: width
			};
		}
		function _createPlaceholder(itemId, columnName) {
			var pos = _getEditorPosition(itemId, columnName);
			var el = document.createElement("div");
			el.className = "gantt_grid_editor_placeholder";
			el.setAttribute(grid.$config.item_attribute, itemId);
			el.setAttribute("data-column-name", columnName);
			el.setAttribute("data-column-index", grid.getColumnIndex(columnName));

			el.style.cssText = [
				"top:" + pos.top + "px",
				"left:" + pos.left + "px",
				"width:" + pos.width + "px",
				"height:" + pos.height + "px"
			].join(";");

			return el;
		}


		var handlers = [];
		var store = null;
		var controller = {
			_itemId: null,
			_columnName: null,
			_editor: null,
			_editorType: null,
			_placeholder: null,

			locateCell: _getGridCellFromNode,
			getEditorConfig: function (columnName) {
				var column = grid.getColumn(columnName);
				return column.editor;
			},

			init: function () {
				var mapping = keyboardMapping.getMapping();
				if(mapping.init){
					mapping.init(this, grid);
				}

				store = grid.$gantt.getDatastore(grid.$config.bind);

				var self = this;

				handlers.push(store.attachEvent("onIdChange", function(oldId, newId){
					if(self._itemId == oldId){
						self._itemId = newId;
					}
				}));
				handlers.push(store.attachEvent("onStoreUpdated", function(){
					if(grid.$gantt.getState("batchUpdate").batch_update){
						return;
					}

					if(self.isVisible() && !store.isVisible(self._itemId)){
						self.hide();
					}
				}));

				this.init = function(){};
			},

			getState: function(){
				return {
					editor: this._editor,
					editorType: this._editorType,
					placeholder: this._placeholder,
					id: this._itemId,
					columnName: this._columnName
				};
			},

			startEdit: function(itemId, columnName) {
				if (this.isVisible()) {
					this.save();
				}

				if(!store.exists(itemId)){
					return;
				}

				var editorState = {id: itemId, columnName: columnName};
				if(this.callEvent("onBeforeEditStart", [editorState]) === false){
					return;
				}

				this.show(editorState.id, editorState.columnName);
				this.setValue();

				this.callEvent("onEditStart", [editorState]);
			},
			isVisible: function(){
				return !!(this._editor && domHelpers.isChildOf(this._placeholder, document.body));
			},
			show: function (itemId, columnName) {
				if (this.isVisible()) {
					this.save();
				}
				var editorState = {id: itemId, columnName: columnName};

				var column = grid.getColumn(editorState.columnName);
				var editorConfig = this.getEditorConfig(column.name);
				if(!editorConfig)
					return;

				var editor = grid.$getConfig().editor_types[editorConfig.type];

				var placeholder = _createPlaceholder(editorState.id, editorState.columnName);
				grid.$grid_data.appendChild(placeholder);
				editor.show(editorState.id, column, editorConfig, placeholder);
				this._editor = editor;
				this._placeholder = placeholder;
				this._itemId = editorState.id;
				this._columnName = editorState.columnName;
				this._editorType = editorConfig.type;

				var mapping = keyboardMapping.getMapping();
				if(mapping.onShow){
					mapping.onShow(this, placeholder, grid);
				}
			},

			setValue: function () {
				var state = this.getState();
				var itemId = state.id,
					columnName = state.columnName;

				var column = grid.getColumn(columnName);
				var item = store.getItem(itemId);
				var editorConfig = this.getEditorConfig(columnName);

				if(!editorConfig)
					return;

				var value = item[editorConfig.map_to];
				if(editorConfig.map_to == "auto"){
					value = store.getItem(itemId);
				}

				this._editor.set_value(value, itemId, column, this._placeholder);
				this.focus();
			},

			focus: function(){
				this._editor.focus(this._placeholder);
			},

			getValue: function () {
				var column = grid.getColumn(this._columnName);
				return this._editor.get_value(this._itemId, column, this._placeholder);
			},

			_getItemValue: function() {
				var editorConfig = this.getEditorConfig(this._columnName);

				if(!editorConfig)
					return;

				var item = gantt.getTask(this._itemId);
				var value = item[editorConfig.map_to];
				if(editorConfig.map_to == "auto"){
					value = store.getItem(this._itemId);
				}
				return value;
			},

			isChanged: function(){

				var column = grid.getColumn(this._columnName);

				var value = this._getItemValue();

				return this._editor.is_changed(value, this._itemId, column, this._placeholder);
			},

			hide: function () {
				if(!this._itemId)
					return;

				var itemId = this._itemId,
					columnName = this._columnName;

				var mapping = keyboardMapping.getMapping();
				if(mapping.onHide){
					mapping.onHide(this, this._placeholder, grid);
				}

				this._itemId = null;
				this._columnName = null;
				this._editorType = null;
				if (!this._placeholder) return;

				if (this._editor) {
					this._editor.hide(this._placeholder);
				}
				this._editor = null;
				if (this._placeholder.parentNode) {
					this._placeholder.parentNode.removeChild(this._placeholder);
				}
				this._placeholder = null;

				this.callEvent("onEditEnd", [{id: itemId, columnName: columnName}]);
			},
			save: function () {
				if(!(this.isVisible() && store.exists(this._itemId) && this.isChanged())) {
					this.hide();
					return;
				}

				var itemId = this._itemId,
					columnName = this._columnName;

				if(!store.exists(itemId)) {
					return;
				}

				var item = store.getItem(itemId);
				var editorConfig = this.getEditorConfig(columnName);
				var editorState = {
					id: itemId,
					columnName: columnName,
					newValue: this.getValue(),
					oldValue: this._getItemValue()
				};
				if(this.callEvent("onBeforeSave", [editorState]) !== false) {
					if(this._editor.is_valid(editorState.newValue, editorState.id, editorState.columnName, this._placeholder)){

						var value = editorState.newValue;
						if (editorConfig.map_to != "auto") {
							item[columnName] = value;
							if (columnName == "duration") {
								item.end_date = gantt.calculateEndDate(item);
							} else if (columnName == "end_date") {
								item.start_date = gantt.calculateEndDate({
									start_date: item.end_date,
									duration: -item.duration,
									task: item}
									);
							} else if (columnName == "start_date") {
								item.end_date = gantt.calculateEndDate(item);
							}

							store.updateItem(itemId);
						}else{
							this._editor.save(itemId, grid.getColumn(columnName), this._placeholder);
						}
						this.callEvent("onSave", [editorState]);
					}
				}
				this.hide();
			},

			_findEditableCell: function findEditableCell(start, direction){
				var nextIndex = start;
				var columns = grid.getGridColumns();
				var nextColumn = columns[nextIndex];

				var columnName = nextColumn ? nextColumn.name : null;
				if(columnName){
					while(columnName && !this.getEditorConfig(columnName)){
						columnName = this._findEditableCell(start + direction, direction);
					}
					return columnName;
				}
				return null;
			},

			getNextCell: function moveCell(dir){
				return this._findEditableCell(grid.getColumnIndex(this._columnName) + dir, dir);
			},

			getFirstCell: function getFirstCell(){
				return this._findEditableCell(0, 1);
			},

			getLastCell: function getLastCell(){
				return this._findEditableCell(grid.getGridColumns().length - 1, -1);
			},

			editNextCell: function nextCell(canChangeRow){
				var cell = this.getNextCell(1);
				if(cell){
					var nextColumn = this.getNextCell(1);
					if(nextColumn && this.getEditorConfig(nextColumn)){
						this.startEdit(this._itemId, nextColumn);
					}
				}else if(canChangeRow && this.moveRow(1)){
					var task = this.moveRow(1);
					cell = this.getFirstCell();
					if(cell && this.getEditorConfig(cell)){
						this.startEdit(task, cell);
					}
				}
			},

			editPrevCell: function prevCell(canChangeRow){
				var cell = this.getNextCell(-1);
				if(cell){
					var nextColumn = this.getNextCell(-1);
					if(nextColumn && this.getEditorConfig(nextColumn)){
						this.startEdit(this._itemId, nextColumn);
					}
				}else if(canChangeRow && this.moveRow(-1)){
					var task = this.moveRow(-1);
					cell = this.getLastCell();
					if(cell && this.getEditorConfig(cell)){
						this.startEdit(task, cell);
					}
				}
			},

			moveRow: function moveRow(dir){
				if(dir > 0){
					return gantt.getNext(this._itemId);
				}else {
					return  gantt.getPrev(this._itemId);
				}
			},

			editNextRow: function nextRow(){
				var row = this.getNextCell(1);
				if(row){
					this.startEdit(row, this._columnName);
				}
			},

			editPrevRow: function prevRow(){
				var row = this.getNextCell(-1);
				if(row){
					this.startEdit(row, this._columnName);
				}
			},
			destructor: function(){
				handlers.forEach(function(handlerId){
					store.detachEvent(handlerId);
				});
				store = null;
				this.hide();
				this.detachAllEvents();
			}
		};

		utils.mixin(controller, keyboardMapping);
		utils.mixin(controller, eventBus);

		return controller;
	}


	var inlineEditController = {
		init: initConfigs,
		createEditors: createGridEditors
	};

	utils.mixin(inlineEditController, keyboardMapping);
	utils.mixin(inlineEditController, eventBus);

	return inlineEditController;
}

module.exports = create;