var units = {
	"second": 1,
	"minute": 60,
	"hour": 60 * 60,
	"day": 60 * 60 * 24,
	"week": 60 * 60 * 24 * 7,
	"month": 60 * 60 * 24 * 30,
	"quarter": 60 * 60 * 24 * 30 * 3,
	"year": 60 * 60 * 24 * 365
};
function getSecondsInUnit(unit){
	return units[unit] || units.hour;
}

function forEach(arr, callback){
	if(arr.forEach){
		arr.forEach(callback);
	}else{
		var workArray = arr.slice();
		for(var i = 0; i < workArray.length; i++){
			callback(workArray[i], i);
		}
	}
}

function arrayMap(arr, callback){
	if(arr.map){
		return arr.map(callback);
	}else{
		var workArray = arr.slice();
		var resArray = [];

		for(var i = 0; i < workArray.length; i++){
			resArray.push(callback(workArray[i], i));
		}

		return resArray;
	}

}

module.exports = {
	getSecondsInUnit: getSecondsInUnit,
	forEach: forEach,
	arrayMap: arrayMap
};