var domHelpers = require("../utils/dom_helpers"),
	helpers = require("../utils/helpers");

module.exports = function(gantt){
	var calculateScaleRange = require("./gantt_data_range");

	gantt.assert = require("./common/assert")(gantt);

//initial initialization
	gantt.init = function(node, from, to){
		if(from && to){
			this.config.start_date = this._min_date = new Date(from);
			this.config.end_date = this._max_date = new Date(to);
		}
		this.date.init();

		if (!this.config.scroll_size)
			this.config.scroll_size = domHelpers.getScrollSize() || 1;

		var resizeDelay;
		gantt.event(window, "resize", function(){
			clearTimeout(resizeDelay);
			resizeDelay = setTimeout(function(){
				gantt.render();
			}, 300);
		});

		//can be called only once
		this.init = function(node){
			if (this.$container && this.$container.parentNode){
				this.$container.parentNode.removeChild(this.$container);
				this.$container = null;
			}

			if(this.$layout){
				this.$layout.clear();
			}
			this._reinit(node);
		};

		this._reinit(node);
	};

	gantt._reinit = function(node){
		this.callEvent("onBeforeGanttReady", []);
		//this._init_tasks_range();
		this.resetLightbox();
		this._update_flags();


		var config = this.$services.getService("templateLoader");
		config.initTemplates(this);

		this._clearTaskLayers();
		this._clearLinkLayers();

		//this.clear
		if(this.$layout){
			this.$layout.destructor();
			this.$ui.reset();
		}

		this.$root = domHelpers.toNode(node);
		if(this.$root){
			this.$root.innerHTML = "";
		}
		this.$root.gantt = this;
		calculateScaleRange(this);
		this.config.layout.id = "main";
		this.$layout = this.$ui.createView("layout", node, this.config.layout);

		this.$layout.attachEvent("onBeforeResize", function(){
			var storeNames = gantt.$services.getService("datastores");
			for(var i = 0; i < storeNames.length; i++){
				gantt.getDatastore(storeNames[i]).filter();
			}
		});

		this.$layout.attachEvent("onResize", function(){
			gantt.refreshData();
		});

		this.callEvent("onGanttLayoutReady", []);
		this.$layout.render();

		gantt.$container = this.$layout.$container.firstChild;
		this.callEvent("onTemplatesReady",[]);
		this.$mouseEvents.reset(this.$root);
		this.callEvent("onGanttReady", []);

		this.render();
	};

	gantt.$click={
		buttons:{
			"edit":function(id){
				gantt.showLightbox(id);
			},
			"delete":function(id){
				var question = gantt.locale.labels.confirm_deleting;
				var title = gantt.locale.labels.confirm_deleting_title;

				gantt._dhtmlx_confirm(question, title, function(){
					if(!gantt.isTaskExists(id)){
						gantt.hideLightbox();
						return;
					}

					var task = gantt.getTask(id);
					if(task.$new){
						gantt.silent(function(){
							gantt.deleteTask(id, true);
						});
						gantt.refreshData();
					}else{
						gantt.deleteTask(id);
					}

					gantt.hideLightbox();
				});
			}
		}
	};

//renders self
	gantt.render = function(){
		this.callEvent("onBeforeGanttRender", []);

		if (!this.config.sort && this._sort) {
			this._sort = undefined;
		}

		var pos = this.getScrollState();
		var posX = pos ? pos.x : 0;
		if(this._getHorizontalScrollbar()){
			var scrollbar = this._getHorizontalScrollbar();
			posX = scrollbar.$config.codeScrollLeft || posX || 0;
		}


		var visible_date = null;
		if(posX){
			visible_date = gantt.dateFromPos(posX + this.config.task_scroll_offset);
		}
		calculateScaleRange(this);

		this.$layout.$config.autosize = this.config.autosize;
		this.$layout.resize();

		if(this.config.preserve_scroll && pos){

			if(posX){
				var new_pos = gantt.getScrollState();
				var new_date = gantt.dateFromPos(new_pos.x);
				if(!(+visible_date == +new_date && new_pos.y == pos.y)){
					if(visible_date){
						this.showDate(visible_date);
					}
					if(pos.y)
						gantt.scrollTo(undefined, pos.y);
				}
			}
		}

		this.callEvent("onGanttRender", []);
	};

	//TODO: add layout.resize method that wouldn't trigger data repaint
	gantt.setSizes = gantt.render;

	gantt.locate = function(e) {
		var trg = domHelpers.getTargetNode(e);

		//ignore empty cells
		var className = domHelpers.getClassName(trg);
		if ((className || "").indexOf("gantt_task_cell") >= 0) return null;

		var targetAttribute = arguments[1] || this.config.task_attribute;

		var node = domHelpers.locateAttribute(trg, targetAttribute);
		if(node){
			return node.getAttribute(targetAttribute);
		}else{
			return null;
		}
	};

	gantt._locate_css = function(e, classname, strict){
		return domHelpers.locateClassName(e, classname, strict);
	};

	gantt._locateHTML = function(e, attribute) {
		return domHelpers.locateAttribute(e, attribute || this.config.task_attribute);
	};

	gantt.getTaskRowNode = function(id) {
		var els = this.$grid_data.childNodes;
		var attribute = this.config.task_attribute;
		for (var i = 0; i < els.length; i++) {
			if (els[i].getAttribute) {
				var value = els[i].getAttribute(attribute);
				if (value == id) return els[i];
			}
		}
		return null;
	};

	gantt.changeLightboxType = function(type){
		if(this.getLightboxType() == type)
			return true;
		gantt._silent_redraw_lightbox(type);
	};


	gantt._get_link_type = function (from_start, to_start) {
		var type = null;
		if (from_start && to_start) {
			type = gantt.config.links.start_to_start;
		} else if (!from_start && to_start) {
			type = gantt.config.links.finish_to_start;
		} else if (!from_start && !to_start) {
			type = gantt.config.links.finish_to_finish;
		} else if (from_start && !to_start) {
			type = gantt.config.links.start_to_finish;
		}
		return type;
	};

	gantt.isLinkAllowed = function (from, to, from_start, to_start) {
		var link = null;
		if (typeof(from) == "object") {
			link = from;
		} else {
			link = {source: from, target: to, type: this._get_link_type(from_start, to_start)};
		}

		if (!link) return false;
		if (!(link.source && link.target && link.type)) return false;
		if (link.source == link.target) return false;

		var res = true;
		//any custom rules
		if (this.checkEvent("onLinkValidation"))
			res = this.callEvent("onLinkValidation", [link]);

		return res;
	};


	gantt._correct_dst_change = function(date, prevOffset, step, unit){
		var time_unit = helpers.getSecondsInUnit(unit) * step;
		if(time_unit > 60*60 && time_unit < 60*60*24){
			//correct dst change only if current unit is more than one hour and less than day (days have own checking), e.g. 12h
			var offsetChanged = date.getTimezoneOffset() - prevOffset;
			if(offsetChanged){
				date = gantt.date.add(date, offsetChanged, "minute");
			}
		}
		return date;
	};

	gantt.getGridColumns = function(){
		return gantt.config.columns.slice();
	};

	gantt.isSplitTask = function(task){
		return (task.render == "split" && gantt.hasChild(task.id));
	};

	gantt._is_icon_open_click = function(e) {
		if (!e)
			return false;
		var target = e.target || e.srcElement;
		if (!(target && target.className))
			return false;
		var className = domHelpers.getClassName(target);
		if (className.indexOf("gantt_tree_icon") !== -1 && (className.indexOf("gantt_close") !== -1 || className.indexOf("gantt_open") !== -1))
			return true;
		return false;
	};

};